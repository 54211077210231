import { Button, Stack, Typography } from "@mui/material";
import FullscreenBackground from "../FullscreenBackground";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import IconLoader from "../IconLoader";
import { convertPxToRem } from "../../utils";

interface UserRatingFeedbackErrorProps {
  onTryAgain: Function;
}

export default function UserRatingFeedbackError({
  onTryAgain,
}: UserRatingFeedbackErrorProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onTryAgainButtonClick() {
    onTryAgain();
  }

  function onSkipButtonClick() {
    navigate("/home");
  }

  return (
    <>
      <Stack
        justifyContent="flex-end"
        color="primary.contrastText"
        sx={{
          height: "100%",
        }}
        spacing={4}
        p={2}
      >
        <IconLoader
          icon="ChatCrossIcon"
          sx={{
            fontSize: convertPxToRem(150),
            alignSelf: "center",
          }}
        />

        <Stack textAlign="center" spacing={4} py={4}>
          <Typography variant="h1" color="inherit">
            {t("UserRatingFeedbackError.title")}
          </Typography>
          <Stack spacing={2}>
            <Typography variant="body1" color="inherit">
              {t("UserRatingFeedbackError.subTitle")}
            </Typography>
            {(
              t<any, any, string[]>("UserRatingFeedbackError.body", {
                returnObjects: true,
              }) as []
            ).map((text: string, index: number) => (
              <Typography
                key={String(text + index)}
                variant="body1"
                color="inherit"
                px={2}
              >
                {text}
              </Typography>
            ))}
          </Stack>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Button
            onClick={onSkipButtonClick}
            color="info"
            variant="outlined"
            fullWidth
          >
            {t("UserRatingFeedbackError.skipButtonLabel")}
          </Button>
          <Button onClick={onTryAgainButtonClick} color="info" fullWidth>
            {t("UserRatingFeedbackError.tryAgainButtonLabel")}
          </Button>
        </Stack>
      </Stack>
      <FullscreenBackground color="primary.main" />
    </>
  );
}
