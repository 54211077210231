import { Stack } from "@mui/material";
import MoreInfo from ".";

export default function IntercareMedicalAssistanceMoreInfoExamples() {
  function onBackButtonClick() {
    console.log("Back button clicked");
  }

  return (
    <Stack>
      <MoreInfo onBackButtonClick={onBackButtonClick} />
    </Stack>
  );
}
