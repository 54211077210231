import { Typography, Stack, MenuItem, Select } from "@mui/material";
import ChevronDownIcon from "../../theme/icons/ChevronDownIcon";
import { useState } from "react";
import { Membership } from "../../services/core-api-adapter";

interface MedicalAssistanceMembersDropdownProps {
  selectMemberLabel: string;
  selectMemberPlaceholder: string;
  memberships: Membership[];
  setSelectedMembershipId: Function;
}

export default function MedicalAssistanceMembersDropdown({
  selectMemberLabel,
  selectMemberPlaceholder,
  memberships,
  setSelectedMembershipId,
}: MedicalAssistanceMembersDropdownProps) {
  const [selectedMemberMembershipID, setSelectedMemberMembershipID] =
    useState<string>("");

  function handleSetSelectedMemberMembershipID(id: any) {
    setSelectedMembershipId(id);
  }

  return (
    <Stack spacing={2}>
      <Typography align="left" variant="h3">
        {selectMemberLabel}
      </Typography>
      <Select
        fullWidth
        value={selectedMemberMembershipID}
        displayEmpty
        IconComponent={ChevronDownIcon}
        inputProps={{
          "aria-label": selectMemberPlaceholder,
        }}
        MenuProps={{
          "aria-label": selectMemberPlaceholder,
        }}
        onChange={(event: any) => {
          setSelectedMemberMembershipID(event.target.value);
          handleSetSelectedMemberMembershipID(event.target.value);
        }}
        renderValue={
          selectedMemberMembershipID
            ? undefined
            : () => (
                <Typography
                  sx={{
                    color: (theme) => theme.palette.neutral[700],
                    textAlign: "left",
                  }}
                >
                  {selectMemberPlaceholder}
                </Typography>
              )
        }
      >
        {memberships[0].members.map((member, index: number) => {
          const label = member.memberDetails.memberFullName;
          const value = member.membershipDetails.id;
          const key = `${label}_${index}`;

          return (
            <MenuItem aria-label={`${label}`} key={key} value={value}>
              <Stack textAlign={"left"}>{label}</Stack>
            </MenuItem>
          );
        })}
      </Select>
    </Stack>
  );
}
