import { Box, Button, Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import theme from "../../../../theme";
import { convertPxToRem } from "../../../../utils";
import FullscreenBackground from "../../../FullscreenBackground";
import IconLoader from "../../../IconLoader";
import WatermarkIcon from "../../../WatermarkIcon";
import DoctorOnDemandInPersonHelpModal from "../../../InPersonHelpModal";
import { useState } from "react";

interface DoctorOnDemandPromptProps {
  onBackButtonClick: () => void;
  onContinueButtonClick: () => void;
  onFindADoctorButtonClick: () => void;
}

function StepsCounter({ stepCounter }: any) {
  return (
    <Box
      sx={{
        position: "relative",
        height: 30,
        width: 30,
        alignSelf: "center",
      }}
    >
      <IconLoader
        icon="HandDrawnCircleIcon"
        sx={{
          color: "accent2.light",
          fontSize: convertPxToRem(30),
          alignSelf: "center",
          position: "absolute",
          left: "0",
        }}
      />
      <Typography
        sx={{
          fontWeight: 700,
          position: "relative",
          zIndex: 1,
          top: "50%",
          left: "50%",
          textAlign: "center",
          transform: "translate(-50%, -50%)",
          color: "accent2.dark",
          mr: 2,
        }}
      >
        {stepCounter}
      </Typography>
    </Box>
  );
}

export function DoctorOnDemandPrompt({
  onContinueButtonClick,
  onBackButtonClick,
  onFindADoctorButtonClick,
}: DoctorOnDemandPromptProps) {
  const { t } = useTranslation();

  const [isPersonHelpModalOpen, setIsPersonHelpModalOpen] = useState(false);

  function onContinue() {
    onContinueButtonClick();
  }

  function onInPersonHelpButtonClick() {
    setIsPersonHelpModalOpen(true);
  }

  return (
    <>
      <Stack p={2} justifyContent="space-between" flexGrow={1} minHeight="100%">
        <Stack
          color="neutral.50"
          sx={{
            height: "100%",
          }}
        >
          <Stack spacing={6}>
            <Stack spacing={2}>
              <Typography
                textAlign="center"
                color="primary.contrastText"
                variant="h2"
              >
                {t("DoctorOnDemand.prompt.title")}
              </Typography>
              <Stack
                direction="row"
                alignSelf="center"
                alignItems="center"
                spacing={2.5}
                p={2}
              >
                <WatermarkIcon
                  height={36}
                  width={36}
                  color={"neutral.50"}
                  foreground="neutral.50"
                  Icon={"FaceIconBackground"}
                  iconSize="large"
                />

                <IconLoader
                  icon="ArrowNextIcon"
                  sx={{
                    fontSize: convertPxToRem(25),
                    alignSelf: "center",
                    color: "neutral.50",
                  }}
                />
                <Box
                  src={`/assets/MedicalAssistancePartners/Intercare/logo.svg`}
                  component="img"
                  sx={{
                    zIndex: 2,
                  }}
                  alt={t("CVPFlow.screens.HealthRecord.animationAlternateText")}
                />
              </Stack>
            </Stack>

            <Stack
              bgcolor="neutral.50"
              borderRadius={theme.shape.borderRadius}
              p={4}
              spacing={2}
            >
              <Typography textAlign="left" variant="h2">
                {t("DoctorOnDemand.prompt.panel.title")}
              </Typography>

              <Stack direction="row">
                <Stack>
                  <StepsCounter stepCounter={1} />
                </Stack>
                <Stack>
                  <Typography ml={2} variant="body1">
                    <Trans i18nKey="DoctorOnDemand.prompt.panel.step1Label"></Trans>
                  </Typography>
                </Stack>
              </Stack>

              <Stack direction="row">
                <Stack>
                  <StepsCounter stepCounter={2} />
                </Stack>

                <Stack>
                  <Stack>
                    <Typography ml={2} variant="body1">
                      <Trans i18nKey="DoctorOnDemand.prompt.panel.step2Label"></Trans>
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction="row">
                <Stack>
                  <StepsCounter stepCounter={3} />
                </Stack>
                <Stack>
                  <Typography ml={2} variant="body1">
                    <Trans i18nKey="DoctorOnDemand.prompt.panel.step3Label"></Trans>
                  </Typography>
                  <Typography ml={2} variant="body2">
                    <Trans i18nKey="DoctorOnDemand.prompt.panel.step3Label.subtext"></Trans>
                  </Typography>
                </Stack>
              </Stack>

              <Stack direction="row">
                <Stack>
                  <StepsCounter stepCounter={4} />
                </Stack>
                <Stack>
                  <Typography ml={2} variant="body1">
                    <Trans i18nKey="DoctorOnDemand.prompt.panel.step4Label"></Trans>
                  </Typography>
                  <Typography ml={2} variant="body2">
                    <Trans i18nKey="DoctorOnDemand.prompt.panel.step4Label.subtext"></Trans>
                  </Typography>
                </Stack>
              </Stack>
              <Button variant="text" onClick={onInPersonHelpButtonClick}>
                {t("DoctorOnDemand.prompt.panel.inPersonHelp.CTA")}
              </Button>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Button
                onClick={onBackButtonClick}
                color="info"
                fullWidth
                variant="outlined"
              >
                {t("common.backButton")}
              </Button>
              <Button onClick={onContinue} color="info" fullWidth>
                {t("common.continueButton")}
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <FullscreenBackground color="primary.main" />
      </Stack>
      <DoctorOnDemandInPersonHelpModal
        isOpen={isPersonHelpModalOpen}
        onFindADoctorButtonClick={onFindADoctorButtonClick}
        onClose={() => setIsPersonHelpModalOpen(false)}
      />
    </>
  );
}
