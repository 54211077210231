import { Button, Stack, Typography } from "@mui/material";
import FullscreenBackground from "../FullscreenBackground";
import IconLoader from "../IconLoader";
import { convertPxToRem } from "../../utils";

interface ThankYouProps {
  onButtonClick: Function;
  buttonLabel: string;
  title: string;
  subTitle?: string;
}

export default function ThankYou({
  onButtonClick,
  buttonLabel,
  title,
  subTitle,
}: ThankYouProps) {
  function onHandleButtonClick() {
    onButtonClick();
  }

  return (
    <Stack
      justifyContent="flex-end"
      color="primary.contrastText"
      textAlign="center"
      p={2}
      sx={{
        height: "100%",
      }}
    >
      <Stack spacing={4} py={8}>
        <IconLoader
          icon="ChatWithRatingIcon"
          color="primary"
          sx={{
            fontSize: convertPxToRem(150),
            alignSelf: "center",
          }}
        />
        <Typography color="inherit" variant="h1">
          {title}
        </Typography>
        {subTitle && (
          <Typography color="inherit" variant="body1">
            {subTitle}
          </Typography>
        )}
      </Stack>

      <Button onClick={onHandleButtonClick} color="info" fullWidth>
        {buttonLabel}
      </Button>

      <FullscreenBackground color="primary.main" />
    </Stack>
  );
}
