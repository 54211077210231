import { Button, Stack, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  GetMemberConsultationsItem,
  ProductAdministratorSupportedServiceChannelAvailability,
  MemberConsultationOutcome,
  getDefaultPayGMedicalAssistanceAvailabilityData,
  VALID_CONSULTATION_OUTCOMES,
  MemberConsultationChargeCode,
} from "../../services/core-api-adapter";
import { shadows } from "../../theme";
import {
  getCurrentFormattedTime,
  getCurrentFormattedDay,
  convertPxToRem,
} from "../../utils";
import IconLoader from "../IconLoader";

interface ConsultationCardProps {
  consultation: GetMemberConsultationsItem;
  memberName: string;
  onConsultationClick: (consultation: GetMemberConsultationsItem) => void;
  shouldDisplayStatus?: boolean;
  availabilityData?: ProductAdministratorSupportedServiceChannelAvailability[];
}

export default function ConsultationCard({
  consultation,
  memberName,
  onConsultationClick,
  availabilityData,
  shouldDisplayStatus = false,
}: ConsultationCardProps) {
  const { t } = useTranslation();

  function getConsultationStatus(consultation: GetMemberConsultationsItem) {
    switch (consultation.outcome) {
      case MemberConsultationOutcome.TRIAGE_STARTED:
        return {
          text: t("ConsultationCard.finishSymptomAssessment.title"),
          icon: "ClipBoardHealthIcon",
        };
      case MemberConsultationOutcome.REFUND_REQUESTED:
        return {
          text: t("ConsultationCard.refundRequested.title"),
          icon: "CardInHandIcon",
        };
      case MemberConsultationOutcome.PAYMENT_REQUESTED:
      case MemberConsultationOutcome.PAYMENT_FAILED:
        return {
          text: t("ConsultationCard.continuePayment.title"),
          icon: "CardForwardIcon",
        };
      default:
        return {
          text: t("ConsultationCard.continueConsultation.title"),
          icon: "ChatBubblePlusIcon",
        };
    }
  }

  function getConsultationType() {
    switch (consultation.chargeCode) {
      case MemberConsultationChargeCode.CHAT_TO_A_NURSE:
        return t("ActiveConsultations.nurseConsult.continueSessionLabel");
      case MemberConsultationChargeCode.DIGITAL_DOCTOR_CONSULT:
        return t("ActiveConsultations.doctorConsult.continueSessionLabel");
      default:
        return t("ActiveConsultations.continueSessionLabel");
    }
  }

  function getConsultationRedirectURL(
    consultation: GetMemberConsultationsItem
  ) {
    const currentTime = getCurrentFormattedTime();
    const currentDay = getCurrentFormattedDay();

    const defaultAvailabilityData =
      getDefaultPayGMedicalAssistanceAvailabilityData();

    const payGMedicalAssistanceAvailabilityData = availabilityData
      ? availabilityData
      : defaultAvailabilityData;

    const operatingHours = payGMedicalAssistanceAvailabilityData.find(
      (day) => day.day === currentDay
    );
    const [openingTime, closingTime] = operatingHours?.operatingHours?.split(
      " - "
    ) as any;

    if (
      VALID_CONSULTATION_OUTCOMES.includes(consultation.outcome) &&
      currentTime >= openingTime &&
      currentTime <= closingTime
    ) {
      return consultation.redirectUrl;
    }

    return undefined;
  }

  const consultationStatus = getConsultationStatus(consultation);

  return (
    <Button
      onClick={() => onConsultationClick(consultation)}
      aria-label={t("GetMedicalAssistanceCTAButton.continue.label")}
      component="a"
      target="_blank"
      href={getConsultationRedirectURL(consultation)}
      color="primary"
      size="large"
      fullWidth
      endIcon={<IconLoader icon="ArrowInCircleIcon" />}
      style={{ justifyContent: "space-between" }}
    >
      <Stack textAlign="left" spacing={1}>
        <Stack>
          <Typography variant="h4" color="primary.contrastText">
            {getConsultationType()}
          </Typography>

          <Typography variant="h5" color="primary.contrastText">
            {t("ActiveConsultations.continueSessionSubLabel")}
            <Box component="span" ml={0.5}>
              {memberName || t("ActiveConsultations.unknownLabel")}
            </Box>
          </Typography>
        </Stack>
        {shouldDisplayStatus && (
          <Box
            sx={{
              backgroundColor: "background.paper",
              borderRadius: 1,
              boxShadow: shadows.light,
              px: 1,
              py: 0.5,
              width: "fit-content",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={0.5}
            >
              <IconLoader
                icon={consultationStatus.icon}
                color="primary"
                sx={{
                  fontSize: convertPxToRem(20),
                }}
              />
              <Typography variant="subtitle2" color="primary" fontWeight={600}>
                {consultationStatus.text}
              </Typography>
            </Stack>
          </Box>
        )}
      </Stack>
    </Button>
  );
}
