import { ActiveConsultationOutstandingPayment } from ".";
import { MemberConsultationChargeCode } from "../../../../services/core-api-adapter";

export default function ActiveConsultationOutstandingPaymentExamples() {
  function onContinue() {
    console.log("onContinue");
  }

  function onBack() {
    console.log("onBack");
  }

  return (
    <>
      <ActiveConsultationOutstandingPayment
        priceFormatted={"R175"}
        redirectUrl={"https://www.google.com/"}
        chargeCode={"A123" as MemberConsultationChargeCode}
        onContinue={onContinue}
        onBack={onBack}
      />
      <ActiveConsultationOutstandingPayment
        priceFormatted={"R175"}
        redirectUrl={"https://www.google.com/"}
        chargeCode={MemberConsultationChargeCode.DIGITAL_DOCTOR_CONSULT}
        onContinue={onContinue}
        onBack={onBack}
      />
      <ActiveConsultationOutstandingPayment
        priceFormatted={"R175"}
        redirectUrl={"https://www.google.com/"}
        chargeCode={MemberConsultationChargeCode.ONLINE_DOCTOR_CONSULTATION}
        onContinue={onContinue}
        onBack={onBack}
      />
      <ActiveConsultationOutstandingPayment
        priceFormatted={"R175"}
        redirectUrl={"https://www.google.com/"}
        chargeCode={MemberConsultationChargeCode.CHAT_TO_A_NURSE}
        onContinue={onContinue}
        onBack={onBack}
      />
    </>
  );
}
