import { Typography } from "@mui/material";
import { ActiveConsultations } from ".";
import { MemberConsultationOutcome, MemberConsultationStatus } from "../../../../services/core-api-adapter";

export default function ActiveConsultationsExamples() {
  function onActiveSessionSelection(consultation: any) {
    console.log("Active session selected", consultation);
  }

  function onStartNewConsultation() {
    console.log("Starting new session");
  }

  return (
    <>
      <Typography variant="h2" my={4} mx={2} color="neutral.700">
        Multiple active consultations
      </Typography>
      <ActiveConsultations
        consultations={[
          {
            memberId: "ABC124",
            consultationId: "AC12",
            mainMemberId: "AC12",
            isDependant: false,
            chargeAmountFormatted: null,
            chargeCode: null,
            redirectUrl: "",
            outcome: MemberConsultationOutcome.PAYMENT_SUCCEEDED,
            consultationTypeName: "Intercare",
            serviceProviderName: "Intercare",
            startedOn: "",
            status: MemberConsultationStatus.ACTIVE,
          },
          {
            memberId: "ABC124",
            consultationId: "AC12",
            mainMemberId: "AC12",
            isDependant: false,
            chargeAmountFormatted: null,
            chargeCode: null,
            redirectUrl: "",
            outcome: MemberConsultationOutcome.INPERSON_CONSULT,
            consultationTypeName: "Intercare",
            serviceProviderName: "Intercare",
            startedOn: "",
            status: MemberConsultationStatus.ACTIVE,
          },
        ]}
        availabilityData={[
          {
            day: "MONDAY",
            operatingHours: "08:00 – 20:00",
          },
          {
            day: "TUESDAY",
            operatingHours: "08:00 – 20:00",
          },
          {
            day: "WEDNESDAY",
            operatingHours: "08:00 – 20:00",
          },
          {
            day: "THURSDAY",
            operatingHours: "08:00 – 20:00",
          },
          {
            day: "FRIDAY",
            operatingHours: "08:00 – 20:00",
          },
          {
            day: "SATURDAY",
            operatingHours: "08:00 – 18:00",
          },
          {
            day: "SUNDAY",
            operatingHours: "08:00 – 18:00",
          },
          {
            day: "HOLIDAY",
            operatingHours: "08:00 – 18:00",
          },
        ]}
        onActiveConsultationSelected={onActiveSessionSelection}
        onStartNewConsultation={onStartNewConsultation}
      />

      <Typography variant="h2" my={4} mx={2} color="neutral.700">
        1 active consultation
      </Typography>
      <ActiveConsultations
        consultations={[
          {
            memberId: "ABC124",
            consultationId: "AC12",
            mainMemberId: "AC12",
            isDependant: false,
            chargeAmountFormatted: null,
            chargeCode: null,
            redirectUrl: "",
            outcome: MemberConsultationOutcome.INPERSON_CONSULT,
            consultationTypeName: "Intercare",
            serviceProviderName: "Intercare",
            startedOn: "",
            status: MemberConsultationStatus.ACTIVE,
          },
        ]}
        availabilityData={[
          {
            day: "MONDAY",
            operatingHours: "08:00 – 20:00",
          },
          {
            day: "TUESDAY",
            operatingHours: "08:00 – 20:00",
          },
          {
            day: "WEDNESDAY",
            operatingHours: "08:00 – 20:00",
          },
          {
            day: "THURSDAY",
            operatingHours: "08:00 – 20:00",
          },
          {
            day: "FRIDAY",
            operatingHours: "08:00 – 20:00",
          },
          {
            day: "SATURDAY",
            operatingHours: "08:00 – 18:00",
          },
          {
            day: "SUNDAY",
            operatingHours: "08:00 – 18:00",
          },
          {
            day: "HOLIDAY",
            operatingHours: "08:00 – 18:00",
          },
        ]}
        onActiveConsultationSelected={onActiveSessionSelection}
        onStartNewConsultation={onStartNewConsultation}
      />

      <Typography variant="h2" my={4} mx={2} color="neutral.700">
        No active consultations
      </Typography>
      <ActiveConsultations
        consultations={[]}
        availabilityData={[
          {
            day: "MONDAY",
            operatingHours: "08:00 – 20:00",
          },
          {
            day: "TUESDAY",
            operatingHours: "08:00 – 20:00",
          },
          {
            day: "WEDNESDAY",
            operatingHours: "08:00 – 20:00",
          },
          {
            day: "THURSDAY",
            operatingHours: "08:00 – 20:00",
          },
          {
            day: "FRIDAY",
            operatingHours: "08:00 – 20:00",
          },
          {
            day: "SATURDAY",
            operatingHours: "08:00 – 18:00",
          },
          {
            day: "SUNDAY",
            operatingHours: "08:00 – 18:00",
          },
          {
            day: "HOLIDAY",
            operatingHours: "08:00 – 18:00",
          },
        ]}
        onActiveConsultationSelected={onActiveSessionSelection}
        onStartNewConsultation={onStartNewConsultation}
      />
    </>
  );
}
