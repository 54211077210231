import { Button, Stack, Typography } from "@mui/material";
import FullscreenBackground from "../FullscreenBackground";
import { useTranslation } from "react-i18next";
import IconLoader from "../IconLoader";
import { convertPxToRem } from "../../utils";
import { useNavigate } from "react-router";
import theme from "../../theme";

interface PaymentFailureProps {
  onContinue?: Function;
}

export default function PaymentFailure({ onContinue }: PaymentFailureProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onBackHomeButtonClick() {
    if (onContinue) {
      return onContinue();
    }

    return navigate("/home");
  }

  return (
    <Stack
      justifyContent="flex-end"
      color="primary.contrastText"
      sx={{
        height: "100%",
      }}
      p={2}
    >
      <IconLoader
        icon="TrolleyWithFilledCrossIcon"
        color={theme.palette.neutral[50] as any}
        sx={{
          fontSize: convertPxToRem(150),
          alignSelf: "center",
        }}
      />

      <Stack textAlign="center" spacing={4} py={4}>
        <Stack spacing={2}>
          <Typography variant="h1" color="inherit">
            {t("PaymentFailure.title")}
          </Typography>
          <Typography variant="h2" color="inherit">
            {t("PaymentFailure.subTitle")}
          </Typography>
        </Stack>
        <Stack spacing={2}>
          {(
            t<any, any, string[]>(`PaymentFailure.body`, {
              returnObjects: true,
            }) as []
          ).map((text: string, index: number) => (
            <Typography
              variant="body1"
              color="inherit"
              key={String(text) + String(index)}
            >
              {text}
            </Typography>
          ))}
        </Stack>
      </Stack>

      <Button onClick={onBackHomeButtonClick} color="info" fullWidth>
        {t("PaymentFailure.backHome")}
      </Button>

      <FullscreenBackground color="primary.main" />
    </Stack>
  );
}
