import { Button, Stack, Typography } from "@mui/material";
import DefaultDialog from "../DefaultDialog";
import { useTranslation } from "react-i18next";
import IconLoader from "../IconLoader";
import { convertPxToRem } from "../../utils";

interface DoctorOnDemandInPersonHelpModalProps {
  isOpen: boolean;
  onClose: () => void;
  onFindADoctorButtonClick: () => void;
}

export default function DoctorOnDemandInPersonHelpModal({
  isOpen,
  onClose,
  onFindADoctorButtonClick,
}: DoctorOnDemandInPersonHelpModalProps) {
  const { t } = useTranslation();

  return (
    <DefaultDialog
      label={t("DoctorOnDemand.InPersonHelpModal.title")}
      isOpen={isOpen}
      onClose={onClose}
      fullWidth={true}
      paperStyleProps={{
        p: 2,
      }}
    >
      <Stack pt={3} spacing={2}>
        <Typography variant="h3" fontWeight={600} align="center">
          {t("DoctorOnDemand.InPersonHelpModal.title")}
        </Typography>
        <Typography variant="h3" align="center">
          {t("DoctorOnDemand.InPersonHelpModal.subtitle")}
        </Typography>
        <Typography variant="h4" fontWeight={600} align="center">
          {t("DoctorOnDemand.InPersonHelpModal.body.title")}
        </Typography>
        <Stack spacing={2}>
          <>
            {(
              t<any, any, string[]>("DoctorOnDemand.InPersonHelpModal.body", {
                returnObjects: true,
              }) as []
            ).map((text: string, index: number) => (
              <Stack
                direction="row"
                alignItems="top"
                spacing={1}
                key={String(text + index)}
              >
                <IconLoader
                  icon="BulletFilledIcon"
                  sx={{
                    paddingTop: 1,
                    fontSize: convertPxToRem(20),
                  }}
                />
                <Typography variant="body1" color="neutral">
                  {text}
                </Typography>
              </Stack>
            ))}
          </>
        </Stack>
        <Stack
          spacing={2}
          direction={{ md: "row-reverse", sm: "column" }}
          width="100%"
        >
          <Button
            sx={{ flexGrow: 2 }}
            variant="outlined"
            onClick={onFindADoctorButtonClick}
          >
            {t("DoctorOnDemand.InPersonHelpModal.CTA.title")}
          </Button>
          <Button sx={{ flexGrow: 1 }} variant="outlined" onClick={onClose}>
            {t("common.close")}
          </Button>
        </Stack>
      </Stack>
    </DefaultDialog>
  );
}
